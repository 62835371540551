<template>
	<div id="about-us">
        <v-head></v-head>
        <div class="container">
            <div class="banner">
                <h1 class="title">聯絡我們</h1>
            </div>
            <div class="content">
                <p>熱綫電話： (852) 3590 4880</p>
                <p>電郵地址： info@ectcm.com</p>
                <p>Whatsapp： 93839432</p>
                <p>地　　址： 香港數碼港道100號數碼港3座F區5樓 郵箱210號</p>
            </div>
        </div>
        <v-foot></v-foot>
	</div>
</template>

<script>
    import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
	export default {
		components: {
			vHead,
			vFoot
		}
	};
</script>

<style lang="scss" scoped>
    #about-us {
        width: 100%;
    }

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        align-items: center;
        justify-content: flex-start;
    }

    .banner {
        width: 100%;
        height: 146px;
        background-image: url(../../../assets/imgs/pc/aboutus_bannel@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .banner > .title {
        font-size: 42px;
        font-weight: bold;
        color: #231F20;
        margin-top: 45px;
        line-height: 42px;
        position: relative;
    }

    .banner > .title::before {
        content: '';
        position: absolute;
        left: -210px;
        top: 21px;
        width: 180px;
        height: 1px;
        background-color: #98E1E7;
    }

    .banner > .title::after {
        content: '';
        position: absolute;
        right: -210px;
        top: 21px;
        width: 180px;
        height: 1px;
        transform: translateY(-50%);
        background-color: #98E1E7;
    }

    .content {
        width: 1000px;
        margin: 70px auto 155px auto;
        color: #242020;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
    }

    .content > p {
        margin-top: 20px;
    }

    .content > p:nth-child(1) {
        margin-top: 0;
    }
    
    .content > p::first-letter {
        margin-left: 36px;
    }
</style>
